.image-gallery-swiper {
  width: 100%;
  height: 100%;
  --swiper-navigation-color: white;
  --swiper-pagination-color: white;
  /* --swiper-navigation-color: var(--ion-color-primary);
  --swiper-pagination-color: var(--ion-color-primary); */
}

.swiper-slide {
  /* text-align: center;
  font-size: 18px;
  background: #fff; */

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; 
}

.image-gallery-swiper .swiper-slide img {
  display: block;
  width: 100vw;
  height: 100vh;
  /* object-fit: cover; */
  /* object-position: center; */
}

.image-gallery-header {
  position: absolute;
  color: white;
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.2) 50%
  );
}
